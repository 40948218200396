import { VuexModule, Module, Action } from "vuex-module-decorators";
import MaterialsApi from "@/api/materials-api";
import { installationsModule, partnersModule } from '@/store/modules/store-accessor'
import dayjs from "dayjs";


@Module({ name: "materials" })
export default class MaterialsModule extends VuexModule {

  @Action
  async deleteMaterial(data: Record<string, any>) {
    const materialsApi = new MaterialsApi()
    await materialsApi.deleteMaterial(data.id)
    await installationsModule.getInstallationInfo(data.installation_id);
  }

  @Action
  async editMaterial(material: Record<string, any>) {
    const materialsApi = new MaterialsApi()

    if(material.created_at_date && material.created_at_time) {
      material.created_at = dayjs(material.created_at_date + ' ' +  material.created_at_time);
      delete material.created_at_date;
      delete material.created_at_time;
    }

    await materialsApi.updateMaterial(material.id, { cost: material.cost, notes: material.notes, description: material.description,
      assignee_id: material.assignee_id.id,  created_at: material.created_at,
      unit: material.unit.enum_text, unit_quantity: material.unit_quantity, unit_cost: material.unit_cost })
    await installationsModule.getInstallationInfo(material.installation_id);

    if(material.partner_id) {
      await partnersModule.getPartnersInfo(material.partner_id);
    }
  }

}
