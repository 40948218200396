
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  commonModule,
  jobsModule,
  actionTypesModule,
  installationsModule,
  itemsModule,
  itemTypesModule,
  companyModule,
  appointmentsModule,
} from "@/store/modules/store-accessor";
import dayjs from "dayjs";
import { isDecimalNumber, getOptimalTextColor, isNumber, timeChargedUnitLabels, calculateTimeChargingCost } from "@/helpers/data";
import _ from "lodash";
import AssigneeCombo from "@/components/Combobox/AssigneeCombo.vue";
import utc from "dayjs/plugin/utc";
import { TimeUtil } from "@/helpers/TimeUtil";

dayjs.extend(utc);

@Component({
  components: {
    AssigneeCombo,
  },
})
export default class AddJobModal extends Vue {
  @Prop()
  payload!: any;

  isAddingJob = false;

  dateMenu = false;
  scheduledTimeMenu = false;
  addMaterials = false;
  scheduled_start_time = this.formatCurrentTime(new Date());
  addExpenses = false;

  payloadAssignee = {} as any;
  loadingInstallationPrimaryItems = true;
  primaryInstallationItemsCombo = [] as any;

  jobModel = {
    created_at: this.formatDate(new Date()),
    created_at_date: dayjs().format("YYYY-MM-DD"),
    created_at_time: dayjs().format("HH:mm"),
    type_id: [] as any[],
    materials: [
      {
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: '',
        unit_quantity: '1',
        unit_cost: 0,
        unit: null,
        is_unit_based: false
    }] as any,
   assignee_id: "",
   installation_id: "",
   is_complete: false,
   notes: this.payload.notes || '',
   payment_amount: 0,
   is_paid: false,
   radioValue: "user",
   payment_type: this.paymentTypesCombo[1],
   task_id: this.payload.task_id,
   partner_id: this.payload.partner_id || '',
   installation_item_id: {},
   expenses: [{
    id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
    cost: 0,
    notes: '',
  }] as any,
   description: '',
  }

  photoModel = {
    add_photo: false,
    photo: null,
    url: null,
    notes: "",
  };

  typeRule = [
    (val: any) => (val || "").length > 0 || this.$i18n.t("actions_required"),
  ];
  userRule = [(val: any) => !!val || this.$i18n.t("user_required")];
  notesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ];
  expenseNotesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 30 || this.$i18n.t("expenseNotesLengthRule");
    },
  ];
  materialsNotesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ];
  actionNotesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ];
  paymentTypeRule = [
    (val: any) => (val || "").id > 0 || this.$i18n.t("payment_type_required"),
  ];
  scheduledTimeRule = [
    (val: any) => !!val || this.$i18n.t("jobScheduledTimeRequired"),
  ];
  photoRules = [(val: any) => !!val || this.$t("photoRequired")];
  photoNotesRule = [
    (val: any) => val.length <= 50 || this.$i18n.t("notesForPhotoRule"),
  ];
  costRule: any = [
    (val: any) => {
      if (val === "" || isNaN(val)) {
        return this.$i18n.t("cost_required");
      }
      return val >= 0 || this.$i18n.t("cost_required");
    },
  ];

  descriptionRule = [
    (val: any) =>
      val == null ||
      val?.text?.length <= 80 ||
      val?.length <= 80 ||
      this.$i18n.t("client_form_description_length_rule"),
  ];

  scheduledDateRule = [
    (val: any) => !!val || this.$i18n.t("jobScheduledDayRequired"),
  ];

  jobDescriptionRule = [
    (val: any) =>
      val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
  ];

  actualCostRule: any = [];
  paymentRule: any = [];

  nextEventRule = [(val: any) => !!val || this.$i18n.t("nextEventRuleText")];

  reminderRule = [
    (val: any) => (val !== "" && val >= 0) || this.$i18n.t("reminderRuleText"),
  ];

  reminderOptionRule = [
    (val: any) =>
      ((!!val || val == "") && val.text.length > 0) ||
      this.$i18n.t("reminderOptionRuleText"),
  ];

  unitOptionRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  unitRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  unitQuantityRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("unitOptionRule"),
  ]

  itemUnitCostRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  itemUnitQuantityRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  itemUnitRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("unitOptionRule"),
  ]

  get periodicCombo() {
    return commonModule.periodicCombo;
  }

  get jobTotalCost() {
    let actionTotal = this.jobModel.type_id?.reduce(
      (accumulator: number, item: any) => {
        return (
          accumulator +
          (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)
        );
      },
      0
    );
    let materialTotal = this.jobModel.materials?.reduce(
      (accumulator: number, item: any) => {
        return (
          accumulator +
          (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)
        );
      },
      0
    );

    let total = actionTotal;

    if (this.addMaterials) total += materialTotal;

    this.paymentRule = [
      (val: any) => {
        if (val === "" || isNaN(val)) {
          return this.$i18n.t("nonZeroPaymentRule");
        }
        return val > 0 || this.$i18n.t("nonZeroPaymentRule");
      },
      (val: any) => val <= total || this.$i18n.t("actualCostRule"),
    ];

    return total;
  }

  async setActionTypeFromId(action_type_id: any) {
    const foundType = actionTypesModule.actionTypesCombo.find(
      (item: any) => item.id === action_type_id
    );
    if (foundType) {
      this.jobModel.type_id.push({
        text: foundType.text,
        color: foundType.color,
        cost: foundType.cost,
        id: foundType.id,
        is_periodic: foundType.is_periodic,
        next_task: foundType.next_task,
        reminder_option_value: foundType.reminder_option_value,
        reminder_option_combo: foundType.reminder_option_combo,
        periodic_days: foundType.periodic_days,
        periodic_option: foundType.periodic_option,
        reminder_days: foundType.reminder_days,
      }) as any;
    }
  }

  async created() {
    await actionTypesModule.getActionTypesCombo();
    await this.setActionTypeFromId(this.payload.action_type_id);
  }

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get paymentTypesCombo() {
    return commonModule.paymentTypeCombo;
  }

  get isLoadingInstallationPrimaryItems() {
    return this.loadingInstallationPrimaryItems;
  }

  get timeChargedUnitCombo() {
    return commonModule.timeChargedUnitCombo;
  }

  get isLoadingItems() {
    return itemsModule.loadingItemsCombo;
  }

  async hideModal () {
    commonModule.hideModal();
    itemsModule.setInstallationPrimaryItems([]);
  }

  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  onlyNumbers(evt: any) {
    isNumber(evt);
  }

  async beforeMount() {
    await itemsModule.getInstallationPrimaryInventoryItems(
      this.payload.installation.info.id
    );

    if (this.payload.installation_item_id) {
      this.jobModel.installation_item_id = this.itemName({
        primary_items: this.payload.job_item,
        id: this.payload.installation_item_id,
        item_type_text: this.payload.item_type_text,
        item_make: this.payload.item_make,
        item_model: this.payload.item_model,
        item_serial_no: this.payload.item_serial_no,
      });
    } else if (
      this.$route.name === "Calendar" &&
      !this.payload.installation_item_id
    ) {
      this.jobModel.installation_item_id = { primary_items: "", id: 0 };
    } else {
      (this.jobModel.installation_item_id as any) =
        this.defaultInstallationItem();
    }
  }

  async mounted() {
    this.payloadAssignee = this.getAssigneId(this.payload.assignee);
    commonModule.initSnackbar({});
    await actionTypesModule.getActionTypesCombo();
    await commonModule.getPaymentTypeCombo();
    await commonModule.getItemTypeCombo();
    await commonModule.getPersonnelPartnersCombo();
    await itemsModule.getAllInventoryItems("");
    await commonModule.getPeriodicCombo(1);
    this.loadingInstallationPrimaryItems = true;
    await itemsModule.getAllCompanyPrimaryInventoryItems();
    await commonModule.getTimeChargedUnitCombo();
    await itemsModule.getItemsUnitsCombo();
    await itemsModule.getItemsCombo();

    this.loadingInstallationPrimaryItems = false;
  }

  defaultInstallationItem() {
    if (
      !this.payload.task_id && // coming from task
      this.installationPrimaryItems &&
      this.installationPrimaryItems.length === 1
    ) {
      return this.installationPrimaryItems[0];
    }
    return { primary_items: "", id: 0 };
  }

  updateAssignee(assignee: any) {
    this.jobModel.assignee_id = assignee;
  }

  PreviewPhoto() {
    if (this.photoModel.photo !== undefined && this.photoModel.photo !== null) {
      (this.photoModel.url as any) = URL.createObjectURL(this.photoModel.photo);
    } else {
      // Clear photo preview
      (this.photoModel.add_photo as any) = null;
      (this.photoModel.url as any) = "";
      this.photoModel.add_photo = true; // Keep the checkbox checked
    }
  }

  getAssigneId(name: string) {
    const combo: any = commonModule.personnelPartnersCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if (foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return "";
    }
  }

  get installationPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get disablePrimaryItems() {
    return (
      this.loadingInstallationPrimaryItems ||
      this.installationPrimaryItems.length === 0
    );
  }

  repayment() {
    this.jobModel.payment_amount = this.jobTotalCost;
  }

  addMaterial() {
    let newMaterial = {
        id: Math.random() * -1,
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: '',
        notes: '',
        unit_quantity: '1',
        unit_cost: 0,
        unit: null,
        is_unit_based: false
    } as any

    this.jobModel.materials.push(newMaterial);
  }

  deleteMaterial(index: number) {
    if (this.jobModel.materials.length > 1) {
      this.jobModel.materials.splice(index, 1);
    }
  }

  addExpense() {
    let newExpense = {
      id: Math.random() * -1,
      cost: 0,
      notes: "",
    } as any;

    this.jobModel.expenses.push(newExpense);
  }

  deleteExpense(index: number) {
    if (this.jobModel.expenses.length > 1) {
      this.jobModel.expenses.splice(index, 1);
    }
  }

  async doAddJob() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingJob = true;
      let job = _.cloneDeep(this.jobModel);
      this.typeRule = [];
      job.installation_id = this.payload.installation.info.id;

      job.created_at = dayjs(
        this.jobModel.created_at_date + " " + this.jobModel.created_at_time
      )
        .utc()
        .format("DD MMM YYYY HH:mm:ss");

        if(this.addMaterials) {
          job.materials = job.materials.map((material: any) => {
            if (typeof material.description === 'object' && material.description != null) {
              material.description = material.description.text
              material.unit = material.unit.enum_text
              return material
            }
            else {
              return material
            }
          })
        }
        else {
          delete job.materials
        }

      if (!this.addExpenses) {
        delete job.expenses;
      }

      job.type_id.map((item) => {
        item.due_at = item.is_periodic
          ? dayjs(item.next_task)
              .startOf("day")
              .subtract(
                parseInt(item.reminder_option_value) *
                  item.reminder_option_combo.period,
                "days"
              )
              .format("YYYY-MM-DD")
          : null;
      });

      const jobId: any = await jobsModule.createJobWithActions(job);

      if (this.photoModel.add_photo) {
        let formData = new FormData();
        formData.append("image", this.photoModel.photo as any);
        formData.append("notes", this.photoModel.notes as any);
        formData.append("job_id", jobId.id);

        await installationsModule.addInstallationPhoto({
          installationId: job.installation_id,
          photo: formData,
        });
      }
      installationsModule.getInstallationInfo(job.installation_id);

      if (this.$route.name === "Calendar") {
        appointmentsModule.getCalendarAppointmentsAction(
          appointmentsModule.filters
        );
      }

      this.hideModal();
    }
  }

  onDateChange(ev: any) {
    this.jobModel.type_id.map((item) => {
      item.next_task = item.is_periodic
        ? dayjs(ev)
            .startOf("day")
            .add(item.periodic_days, "days")
            .format("YYYY-MM-DD")
        : null;
    });
  }

  onActionTypeChange(ev: any) {
    this.jobModel.type_id.map((item) => {
      item.next_task = item.is_periodic
        ? dayjs(this.jobModel.created_at_date)
            .startOf("day")
            .add(item.periodic_days, "days")
            .format("YYYY-MM-DD")
        : null;
    });
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  formatCurrentTime(date: Date): string {
    return TimeUtil.formatTimeOnly(date);
  }

  formatDate(date: Date): string {
    return TimeUtil.formatDateOnly(date);
  }

  itemName(obj: any) {
    this.primaryInstallationItemsCombo = this.installationPrimaryItems;
    const foundItem = this.primaryInstallationItemsCombo.find(
      (item: any) =>
        item.primary_items[0] == obj.primary_items && item.id === obj.id
    );
    if (foundItem) {
      return foundItem;
    } else {
      if (obj.id) {
        let item_hidden_id = (Math.random() + 1).toString(36).substring(7);
        this.installationPrimaryItems.push({
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        });
        return {
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        };
      } else {
        return { primary_items: "", id: 0 };
      }
    }
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

  calculateTimeChargingCost(action: Record<string, any>) {
    return calculateTimeChargingCost(action);
  }

  timeChargedUnitLabels(unit: Record<string, any>) {
    return timeChargedUnitLabels(unit?.unit);
  }

  get itemsUnitsTypes() {
    return itemsModule.itemsUnitsCombo;
  }

  get inventoryItemsCombo () {
    return itemsModule.itemsCombo;
  }

  itemUnitNumberInputs(evt: Record<string,any>, item: Record<string, any>) {
    if(item.unit.decimals) {
      return isDecimalNumber(evt);
    }
    return isNumber(evt)
  }

  findItemUnit(unitType: Record<string, any>, index: number, material: Record<string, any>) {
    let unitCombo: any = itemsModule.itemsUnitsCombo;
    const foundUnitType = (unitCombo).find((item: any) => item.enum_text == unitType.unit);
    material.unit = { text: foundUnitType?.text, enum_text: foundUnitType?.enum_text, decimals: foundUnitType?.decimals } as any;
    material.is_unit_based = unitType.is_unit_based ?? false;
    material.unit_cost = unitType.cost ?? null;
    material.cost = unitType.cost ?? 0;
    material.unit_quantity = unitType.is_unit_based ? 1 : null;
    // let combo: any = itemsModule.itemsCombo;
    // const foundType = combo.find((item: any) => item.unit == unitType.unit);
    //console.log('JOB Material after change', material)

    // if(foundType?.unit) {
    //   let unitCombo: any = itemsModule.itemsUnitsCombo;
    //   const foundUnitType = unitCombo.find((item: any) => item.enum_text == unitType.unit);

    //   this.jobModel.materials[index].unit = { text: foundUnitType.text, enum_text: foundUnitType.enum_text, decimals: foundUnitType.decimals } as any;
    //   this.jobModel.materials[index].unit_cost = foundType.cost;
    //   this.jobModel.materials[index].cost = foundType.cost;
    //   this.jobModel.materials[index].is_unit_based = foundType.is_unit_based;
    // } else {
    //   this.jobModel.materials[index].unit = { text: '', enum_text: '', decimals: false } as any;
    //   this.jobModel.materials[index].unit_cost = 0;
    //   this.jobModel.materials[index].is_unit_based = false;
    // }
  }

  calcItemUnitCost(index: number) {
    this.jobModel.materials[index].cost = this.jobModel.materials[index].unit_cost * this.jobModel.materials[index].unit_quantity;
  }

};
