
import { Component, Vue, Prop } from 'vue-property-decorator'
import { commonModule, itemsModule, filterModule, itemTypesModule } from '@/store/modules/store-accessor'
import { isDecimalNumber } from '@/helpers/data';

@Component
export default class AddInventoryItemModal extends Vue {
  @Prop()
  payload!: any;

  isAddingItem = false;

  itemModel = {
   make:"",
   model: "",
   model_year: "",
   notes: "",
   warranty: "",
   item_type_id: {id: 0, text: ""},
   cost: 0,
   unit: this.defaultUnitType,
   is_unit_based: false
  }

  get inventoryItemsCombo () {
    return itemTypesModule.itemTypesCombo;
  }

  get itemFilters() {
    return filterModule.itemFilters;
  }

  itemTypeRule= [
    (val: any) => (val || '').text.length > 0 || this.$i18n.t("item_type_required"),
  ]
  makeRule= [
    (val: any) => !!val || this.$i18n.t("make_required"),
  ]
  notesRule= [
    (val: any) => {
      if(val != null) return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ]
  costRule = [ (val: any) => val !== '' && val >= 0 || this.$i18n.t("cost_required")];

  itemUnitRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("itemUnitRequired"),
  ]

  async mounted() {
    commonModule.initSnackbar({});
    await itemTypesModule.getItemTypesCombo();
    await filterModule.getItemFilters();
    await itemsModule.getItemsUnitsCombo();
    await itemsModule.getItemsCombo();
  }

  async hideModal () {
    commonModule.hideModal();
  }

  doAdd () {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isAddingItem = true;
      itemsModule.createItem(this.itemModel);
      this.hideModal()
    }
  }

  isNumber(evt: any) {
      evt = evt || window.event;
      let charCode = evt.which || evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
      return false;
  }

  get itemsUnitsTypes() {
    return itemsModule.itemsUnitsCombo;
  }

  get defaultUnitType() {
    if(itemsModule.itemsUnitsCombo) {
      return itemsModule.itemsUnitsCombo.find((type: Record<string, any>) => type.enum_text === 'PIECES');
    }
    return '';
  }

  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }
};
