
import { Component, Vue, Watch } from 'vue-property-decorator'
import { commonModule, itemsModule, filterModule, itemTypesModule } from '@/store/modules/store-accessor'
import Dropdown from '../components/dropdown/Dropdown.vue'
import DropdownItem from '../components/dropdown/DropdownItem.vue'
import FilterDropdown from '../components/dropdown/filter/FilterDropdown.vue'
import ItemFilter from '../components/Filter/ItemFilter.vue'
import { customSearch, sortTableDates } from "@/helpers/data";

@Component({
  components: { Dropdown, DropdownItem, FilterDropdown, ItemFilter }
})
export default class InventoryItems extends Vue {
  tableheaders: any [] = [
    {
      text: '',
      align: 'left',
      class: 'row-style',
      sortable: false,
    },
    
    { text: this.$t("type"), sortable: true, class: 'row-style', value: 'type_name' },
    { text: this.$t("boiler_make"), sortable: true, class: 'row-style', value: 'make' },
    { text: this.$t("boiler_model"), sortable: true, class: 'row-style', value: 'model' },
    { text: this.$t("model_year"), align: 'left', sortable: true, class: 'row-style', value: 'model_year' },
    { text: this.$t("created"), sortable: true, class: 'row-style', value: 'readable_created_at', sort: (a: string, b: string) => sortTableDates(a, b) },
    { text: this.$t("notes"), sortable: true, class: 'row-style', value: 'notes' },
    { text: '', align: 'right', sortable: false, class: 'row-style', value: 'id' } // Show Dropdown
  ]

  model: any = {
    search: '',
    selected: [] as string[]
  }

 get items(){
    return itemsModule.items
  }

  get itemsLength() {
    return this.items.length;
  }

  get searchLabel() {
    return this.$t("itemsSearch")
  }

  customSearchFilter(value: any, search: any, item: any) {
    return customSearch(value, search, item);
  }

  async mounted () {
    /*
     TODO: Handle installations properly when UX is clear.
     */
    itemsModule.getAllInventoryItems('');
    itemTypesModule.getItemTypesCombo();
    itemsModule.getItemsUnitsCombo();
    filterModule.getItemFilters();
    commonModule.initSnackbar({});

  }

  destroyed() {
    itemsModule.clearItems();
  }

  get isLoadingTable() {
    return itemsModule.isLoadingTable;
  }

 addItem (){
    commonModule.showModal({ name: 'add-inventory-item-modal' })
  }
  
  deleteItem(item: any) {
    commonModule.showModal({ name: 'delete-inventory-item-modal', payload: { deletedItem: item }},)
  }

  editItem(item: any, editId: any) {
    commonModule.showModal({ name: 'edit-inventory-item-modal', payload: { editItem: item }},)
  }

  async refreshData() {
    await itemsModule.getAllInventoryItems(itemsModule.filters);
    await filterModule.getItemFilters();
  }

  timer: number;

  onSearchChange(val: string) {
    clearTimeout(this.timer)

    this.timer = setTimeout(async () => {
      itemsModule.setIsLoadingTable(true);
      if (val && val.length > 0) {
        itemsModule.setSearch(val);
        itemsModule.queryObject.search = itemsModule.search;
      }
      else {
        itemsModule.setSearch('');
        delete itemsModule.queryObject.search;
      }
      this.$router.replace({ query: itemsModule.queryObject });

      // Check if user selected any of the queries
      const queryIndex = this.$route.fullPath.indexOf('?');
      if (queryIndex >= 0) {
        const query = this.$route.fullPath.substring(queryIndex);
        itemsModule.setFilters(query);
      } else {
        itemsModule.setFilters('');
      }

      await itemsModule.getAllInventoryItems(itemsModule.filters);
      itemsModule.setIsLoadingTable(false);
    }, 500);
  }

}
