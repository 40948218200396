import { BaseApi } from '@/helpers/base-api'

export default class InventoryApi extends BaseApi {
  constructor () {
    super('/api/v1/items')
    this.errorMessage = 'There was an issue when trying to fetch the inventory. If this error persists, please contact our support.'
  }

  public getAllInventoryItems = (query: string): Promise<ItemDto[]> => this.instance.get(`${query}`)
  public getInstallationPrimaryInventoryItems = (installationId: string): Promise<ItemDto[]> => this.instance.get(`/${installationId}/primary`)
  public getAllCompanyPrimaryInventoryItems = (): Promise<ItemDto[]> => this.instance.get(`/all/primary/company`)
  public getInventoryItemsCombo = (): Promise<Record<string, any>[]> => this.instance.get(`/combo`)
  public getInventoryItemsUnitsCombo = (): Promise<Record<string, any>[]> => this.instance.get(`/combo/units`)
  
  public createInventoryItem = (data: Record<string, unknown>): Promise<JSON> => this.instance.post('', data)

  public updateInventoryItem = (itemId: string, data: Record<string, unknown>): Promise<JSON> => this.instance.patch(`/${itemId}`, data)

  public deleteInventoryItem = (itemId: string): Promise<JSON> => this.instance.delete(`/${itemId}`)

}
