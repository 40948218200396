
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  commonModule,
  appointmentsModule,
  actionTypesModule,
  installationsModule,
  itemsModule,
  itemTypesModule,
  companyModule,
} from "@/store/modules/store-accessor";
import { isDecimalNumber, getOptimalTextColor, isNumber, timeChargedUnitLabels, calculateTimeChargingCost } from "@/helpers/data";
import AssigneeUserCombo from "@/components/Combobox/AssigneeUserCombo.vue";
import _ from "lodash";
import dayjs from "dayjs";

@Component({
  components: {
    AssigneeUserCombo,
  },
})
export default class EndAppointmentModal extends Vue {
  @Prop()
  payload!: any;

  isEndingAppointment = false;
  addMaterials = false;
  payloadAssignee = {} as any;
  addExpenses = false;
  installationItem = "";

  jobModel = {
    type_id: this.findActionType(this.payload.appointment) as any,
    materials: [
      {
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0, //This needs to be initialized not null for the reduce to work
        description: "",
        notes: "",
        unit_quantity: '1',
        unit_cost: 0,
        unit: null,
      },
    ] as any,
    assignee_id: this.getAssigneeId(this.payload.appointment.assignee_id),
    installation_id: this.payload.installation.info
      ? this.payload.installation.info.id
      : this.payload.appointment.installation_id,
    is_complete: false,
    notes: "",
    payment_amount: null,
    is_paid: false,
    radioValue: "user",
    id: this.payload.appointment.id,
    job_id: this.payload.appointment.job_id,
    payment_type: this.paymentTypesCombo[1],
    installation_item_id: this.payload.appointment.installation_item_id,
    item_type_text: this.payload.appointment.item_type_text,
    item_make: this.payload.appointment.item_make,
    item_model: this.payload.appointment.item_model,
    item_serial_no: this.payload.appointment.item_serial_no,
    expenses: [
      {
        id: Math.random() * -1, //Need Random and non-integer and also negative, for this to work with the backend properly
        cost: 0,
        notes: "",
      },
    ] as any,
    description: this.payload.appointment.description || "",
  };

  photoModel = {
    add_photo: false,
    photo: null,
    url: null,
    notes: "",
  };

  typeRule = [
    (val: any) => (val || "").length > 0 || this.$i18n.t("actions_required"),
  ];
  userRule = [(val: any) => !!val || this.$i18n.t("user_required")];
  notesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("form_notes_length_rule");
      else return true;
    },
  ];
  expenseNotesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 30 || this.$i18n.t("expenseNotesLengthRule");
    },
  ];
  materialNotesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ];
  actionNotesRule = [
    (val: any) => {
      if (val != null)
        return val.length <= 1000 || this.$i18n.t("notesLengthRule");
      else return true;
    },
  ];
  paymentTypeRule = [
    (val: any) => (val || "").id > 0 || this.$i18n.t("payment_type_required"),
  ];
  photoRules = [(val: any) => !!val || this.$t("photoRequired")];
  photoNotesRule = [
    (val: any) => val.length <= 50 || this.$i18n.t("notesForPhotoRule"),
  ];
  costRule: any = [
    (val: any) => (val !== "" && val >= 0) || this.$i18n.t("cost_required"),
  ];
  descriptionRule = [
    (val: any) =>
      val == null ||
      val?.text?.length <= 80 ||
      val?.length <= 80 ||
      this.$i18n.t("client_form_description_length_rule"),
  ];
  paymentRule: any = [];
  jobDescriptionRule = [
    (val: any) =>
      val.length <= 80 || this.$i18n.t("installationDescriptionLengthRule"),
  ];

  itemUnitCostRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  itemUnitQuantityRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  itemUnitRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("unitOptionRule"),
  ]

  nextEventRule = [(val: any) => !!val || this.$i18n.t("nextEventRuleText")];

  reminderRule = [
    (val: any) => (val !== "" && val >= 0) || this.$i18n.t("reminderRuleText"),
  ];

  reminderOptionRule = [
    (val: any) =>
      ((!!val || val == "") && val.text.length > 0) ||
      this.$i18n.t("reminderOptionRuleText"),
  ];

  unitOptionRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  unitRule = [
    (val: any) => (val !== null && val !== "" && val >= 0) || this.$i18n.t("requiredRule"),
  ]

  unitQuantityRule = [
    (val: any) => ((!!val || val == '') && val.text.length > 0) || this.$i18n.t("unitOptionRule"),
  ]

  get periodicCombo() {
    return commonModule.periodicCombo;
  }

  get installationPrimaryItems() {
    return itemsModule.installationPrimaryItems;
  }

  get isLoadingItems() {
    return itemsModule.loadingItemsCombo;
  }

  get itemsUnitsTypes() {
    return itemsModule.itemsUnitsCombo;
  }

  get inventoryItemsCombo () {
    return itemsModule.itemsCombo;
  }

  get jobTotalCost() {
    let actionTotal = this.jobModel.type_id?.reduce(
      (accumulator: number, item: any) => {
        return (
          accumulator +
          (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)
        );
      },
      0
    );
    let materialTotal = this.jobModel.materials?.reduce(
      (accumulator: number, item: any) => {
        return (
          accumulator +
          (!isNaN(parseFloat(item.cost)) ? parseFloat(item.cost) : 0)
        );
      },
      0
    );

    let total = actionTotal;

    if (this.addMaterials) total += materialTotal;

    this.paymentRule = [
      (val: any) => {
        if (val === "" || isNaN(val)) {
          return this.$i18n.t("requiredNonZeroPaymentRule");
        }
        return val > 0 || this.$i18n.t("nonZeroPaymentRule");
      },
      (val: any) => val <= total || this.$i18n.t("actualCostRule"),
    ];

    return total;
  }

  actualCostRule: any = [];

  get actionsCombo() {
    return actionTypesModule.actionTypesCombo;
  }

  get assigneeIdCombo() {
    return commonModule.personnelCombo;
  }

  get paymentTypesCombo() {
    for (let i = 0; i < commonModule.paymentTypeCombo.length; i++) {
      (commonModule.paymentTypeCombo[i]["payment_type"] as any) = this.$t(
        commonModule.paymentTypeCombo[i]["payment_type"]
      );
    }
    return commonModule.paymentTypeCombo;
  }

  get itemTypesCombo() {
    return itemTypesModule.itemTypesCombo;
  }

  get timeChargedUnitCombo() {
    return commonModule.timeChargedUnitCombo;
  }

  onlyNumbers(evt: any) {
    isNumber(evt);
  }

  async hideModal() {
    commonModule.hideModal();
  }

  findActionType(appointment: any) {
    let combo: any = actionTypesModule.actionTypesCombo;
    const foundType = combo.find(
      (item: any) => item.id === appointment.action_type_id
    );
    if (foundType) {
      return [
        {
          text: foundType.text,
          color: foundType.color,
          cost: foundType.cost,
          id: foundType.id,
          is_periodic: foundType.is_periodic,
          next_task: foundType.next_task,
          reminder_option_value: foundType.reminder_option_value,
          reminder_option_combo: foundType.reminder_option_combo,
          is_time_charged: foundType.is_time_charged,
          unit: foundType.unit,
          unit_cost: foundType.unit_cost,
          unit_quantity: foundType.unit_quantity
        },
      ] as any;
    } else {
      return [] as any;
    }
  }

  getAssigneeId(name: string) {
    const combo: any = commonModule.personnelCombo;
    const foundItem = combo.find((item: any) => item.name === name);
    if (foundItem) {
      return { name: foundItem.name, id: foundItem.id };
    } else {
      return "";
    }
  }
  optimalTextColor(color: string) {
    return getOptimalTextColor(color);
  }

  async beforeMount() {
    await itemsModule.getInstallationPrimaryInventoryItems(
      this.jobModel.installation_id
    );
  }

  async mounted() {
    this.installationItem = this.itemName({
      primary_items: this.payload.appointment.job_item,
      id: this.payload.appointment.installation_item_id,
    });
    this.payloadAssignee = this.getAssigneeId(
      this.payload.appointment.assignee_id
    );
    this.calculateTimeChargingCost(this.jobModel.type_id[0], true);
    commonModule.initSnackbar({});
    await commonModule.getPersonnelCombo();
    await actionTypesModule.getActionTypesCombo();
    await commonModule.getPaymentTypeCombo();
    this.jobModel.payment_type = this.paymentTypesCombo[1];
    await itemTypesModule.getItemTypesCombo();
    await itemsModule.getAllInventoryItems("");
    await commonModule.getPeriodicCombo(1);
    await commonModule.getTimeChargedUnitCombo();
    await itemsModule.getItemsUnitsCombo();
    await itemsModule.getItemsCombo();
  }

  updateAssignee(assignee: any) {
    if (assignee) {
      this.jobModel.assignee_id = assignee;
    }
  }

  addPhoto() {
    commonModule.showModal({
      name: "add-photo-to-installation-modal",
      payload: { installation: this.jobModel, tab: "closeAppointment" },
    });
  }

  PreviewPhoto() {
    if (this.photoModel.photo !== undefined && this.photoModel.photo !== null) {
      (this.photoModel.url as any) = URL.createObjectURL(this.photoModel.photo);
    } else {
      // Clear photo preview
      (this.photoModel.add_photo as any) = null;
      (this.photoModel.url as any) = "";
      this.photoModel.add_photo = true; // Keep the checkbox checked
    }
  }

  repayment() {
    this.jobModel.payment_amount = this.jobTotalCost;
  }

  addMaterial() {
    let newMaterial = {
      id: Math.random() * -1,
      cost: 0, //This needs to be initialized not null for the reduce to work
      description: "",
      notes: "",
      unit_quantity: '1',
      unit_cost: 0,
      unit: null,
    } as any;

    this.jobModel.materials.push(newMaterial);
  }

  deleteMaterial(index: number) {
    if (this.jobModel.materials.length > 1) {
      this.jobModel.materials.splice(index, 1);
    }
  }

  addExpense() {
    let newExpense = {
      id: Math.random() * -1,
      cost: 0,
      notes: "",
    } as any;

    this.jobModel.expenses.push(newExpense);
  }

  deleteExpense(index: number) {
    if (this.jobModel.expenses.length > 1) {
      this.jobModel.expenses.splice(index, 1);
    }
  }

  doEndAppointment() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.isEndingAppointment = true;
      let job = _.cloneDeep(this.jobModel);

      if (this.photoModel.add_photo) {
        let formData = new FormData();
        formData.append("image", this.photoModel.photo as any);
        formData.append("notes", this.photoModel.notes as any);

        formData.append("job_id", job.job_id);
        installationsModule.addInstallationPhoto({
          installationId: job.installation_id,
          photo: formData,
        });
      }

      if (this.addMaterials) {
        //TODO Kapou edw einai to provlima i' mesa sto endappointment Map pou evala. 
        job.materials = job.materials.map((material: any) => {
          if (
            typeof material.description === "object" &&
            material.description != null
          ) {
            material.description = material.description.text;
            material.unit = material.unit.enum_text
            return material;
          } else {
            return material;
          }
        });
      } else {
        delete job.materials;
      }

      job.type_id.map((item: any) => {
        item.due_at = item.is_periodic
          ? dayjs(item.next_task)
              .startOf("day")
              .subtract(
                parseInt(item.reminder_option_value) *
                  item.reminder_option_combo.period,
                "days"
              )
              .format("YYYY-MM-DD")
          : null;
      });

      if (!this.addExpenses) {
        delete job.expenses;
      }

      appointmentsModule.getCalendarAppointmentsAction(
        appointmentsModule.filters
      );
      appointmentsModule.endAppointment(job);
      installationsModule.getInstallationInfo(job.installation_id);
      this.hideModal();
    }
  }
  onlyDecimalNumbers(evt: any) {
    isDecimalNumber(evt);
  }

  get jobDescriptionIsEnabled() {
    return (companyModule.company?.preferences as any)?.job_description;
  }

  itemName(obj: any) {
    const combo: any = this.installationPrimaryItems;
    const foundItem = combo.find(
      (item: any) =>
        item.id === obj.id && item.primary_items[0] == obj.primary_items
    );
    if (foundItem) {
      return foundItem.primary_items[0];
    } else {
      if (obj.id) {
        let item_hidden_id = (Math.random() + 1).toString(36).substring(7);
        this.installationPrimaryItems.push({
          ...obj,
          disabled: true,
          hidden_id: item_hidden_id,
        });
        return obj.primary_items;
      } else {
        return "-";
      }
    }
  }

  calculateTimeChargingCost(action: Record<string, any>, initialCalc = false) {
    action.scheduled_start = this.payload.appointment.scheduled_start;
    action.scheduled_end = this.payload.appointment.scheduled_end;
    return calculateTimeChargingCost(action, initialCalc);
  }

  timeChargedUnitLabels(unit: Record<string, any>) {
    return timeChargedUnitLabels(unit?.unit);
  }

  itemUnitNumberInputs(evt: Record<string,any>, item: Record<string, any>) {
    if(item.unit.decimals) {
      return isDecimalNumber(evt);
    }
    return isNumber(evt)
  }

  findItemUnit(unitType: Record<string, any>, index: number, material: Record<string, any>) {
    let unitCombo: any = itemsModule.itemsUnitsCombo;
    const foundUnitType = (unitCombo).find((item: any) => item.enum_text == unitType.unit);
    material.unit = { text: foundUnitType?.text, enum_text: foundUnitType?.enum_text, decimals: foundUnitType?.decimals } as any;
    material.is_unit_based = unitType.is_unit_based ?? false;
    material.unit_cost = unitType.cost ?? null;
    material.cost = unitType.cost ?? 0;
    material.unit_quantity = unitType.is_unit_based ? 1 : null;

    //console.log('Appt Material after change', material);
    // let combo: any = itemsModule.itemsCombo;
    // const foundType = combo.find((item: any) => item.unit == unitType.unit);

    // if(foundType?.unit) {
    //   let unitCombo: any = itemsModule.itemsUnitsCombo;
    //   const foundUnitType = unitCombo.find((item: any) => item.enum_text == unitType.unit);

    //   this.jobModel.materials[index].unit = { text: foundUnitType.text, enum_text: foundUnitType.enum_text, decimals: foundUnitType.decimals } as any;
    //   this.jobModel.materials[index].unit_cost = foundType.cost;
    //   this.jobModel.materials[index].cost = foundType.cost;
    //   this.jobModel.materials[index].is_unit_based = foundType.is_unit_based;
    // } else {
    //   this.jobModel.materials[index].unit = { text: '', enum_text: '' } as any;
    //   this.jobModel.materials[index].unit_cost = 0;
    //   this.jobModel.materials[index].is_unit_based = false;
    // }
  }

  calcItemUnitCost(index: number) {
    this.jobModel.materials[index].cost = this.jobModel.materials[index].unit_cost * this.jobModel.materials[index].unit_quantity;
  }

};
